<template>
  <div id="blog" class="content">
    <!-- <div class="box-inpage out-work">
      <div class="container">
        <el-row :gutter="30">
          <el-col :span="24" :md="10" class="set-center-tb">
            <p class="head-name-page">บทความ</p>
            <span class="sub-name-page">ที่คัดสรรมาเพื่อคุณ</span>
            <p class="small-text">
              SMID ย่านนวัตกรรมการแพทย์สวนดอก งานบริการและงานวิจัย
              ที่ดีที่สุดในภาคเหนือ บริการค้นหาโรงพยาบาล และคลินิกในย่าน
            </p>
            <button
              @click="openModalLogin"
              class="btn btn-color-dark link-regis"
            >
              สมัครสมาชิก
              <img
                src="../assets/image/icon/right_arrow.svg"
                class="right_arrow"
                alt
              />
            </button>
          </el-col>
        </el-row>
      </div>
      <groupSuandok />
    </div> -->
    <div class="container detill-content blog-first">
      <div class="grop-new">
        <p class="blog-new">บทความล่าสุด</p>
        <div class="grop-new-right">
          <el-dropdown @command="selectCategory">
            <span class="el-dropdown-link" v-if="getDataSelect == 'all'">
              ทุกหมวดหมู่<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <span class="el-dropdown-link" v-if="getDataSelect == 'service'">
              Service<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <span
              class="el-dropdown-link"
              v-if="getDataSelect == 'innovation-2'"
            >
              Innovation<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <span class="el-dropdown-link" v-if="getDataSelect == 'business'">
              Business<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <span class="el-dropdown-link" v-if="getDataSelect == 'startup'">
              Startup<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <span class="el-dropdown-link" v-if="getDataSelect == 'article'">
              Article<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <span class="el-dropdown-link" v-if="getDataSelect == 'event'">
              Event<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <span class="el-dropdown-link" v-if="getDataSelect == 'news'">
              News<i class="el-icon-arrow-down el-icon--right"></i>
            </span>

            <el-dropdown-menu slot="dropdown" class="custom-dropdown1">
              <el-dropdown-item command="all">ทุกหมวดหมู่</el-dropdown-item>
              <el-dropdown-item command="service">Service</el-dropdown-item>
              <el-dropdown-item command="innovation-2"
                >Innovation</el-dropdown-item
              >
              <el-dropdown-item command="business">Business</el-dropdown-item>
              <el-dropdown-item command="startup">Startup</el-dropdown-item>
              <el-dropdown-item command="article">Article</el-dropdown-item>
              <el-dropdown-item command="event">Event</el-dropdown-item>
              <el-dropdown-item command="news">News</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-tooltip
            class="item"
            effect="dark"
            content="เรียงลำดับ"
            placement="top-start"
          >
            <el-button
              type="text"
              @click="sortArr(), sort == 1 ? (sort = -1) : (sort = 1)"
            >
              <!-- <span>{{sort}}</span> -->
              <i class="fas fa-sort-amount-down" v-if="sort == 1"></i>
              <i class="fas fa-sort-amount-up" v-else></i>
            </el-button>
          </el-tooltip>
        </div>
      </div>
      <div v-loading="loading" class="min-height-110">
        <el-row :gutter="30" class="set-count-color-card">
          <el-col
            class="set-mt-blog"
            :span="24"
            :sm="24"
            :md="24"
            v-for="(dataBlogs, index) in BlogFirst"
            :key="index"
          >
            <cardBlogLong :dataBlog="dataBlogs" @dataCategory="selectCategory" />
          </el-col>
        </el-row>
        <el-row :gutter="30" class="set-count-color-card">
        <el-col
          class="set-mt-blog"
          :span="24"
          :sm="12"
          :md="8"
          v-for="(dataBlogs, index) in Blog"
          :key="index"
        >
          <cardBlog :dataBlog="dataBlogs" @dataCategory="selectCategory" />
        </el-col>
        </el-row>
        
        <div class="no-blog" v-if="Blog == '' & !loading">
          <p>ไม่พบหมวดหมู่ที่คุณเลือก</p>
        </div>
        <div class="group-pagination" v-else>
          <div v-if="getDataSelect != 'all'">
            <el-pagination
              @current-change="changePageCustom"
              background
              layout="prev, pager, next"
              :total="total"
              :page-size="pageSize"
              :current-page="page"
            ></el-pagination>
          </div>

          <el-pagination
            v-else
            @current-change="changePage"
            background
            layout="prev, pager, next"
            :total="total"
            :page-size="pageSize"
            :current-page="page"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scope>
.no-blog {
  padding-top: 20px;
  text-align: center;
  font-size: 24px;
  color: #606266;
}
.min-height-110{
  min-height: 110px;
}
.el-loading-spinner{
  top:80px;
}
</style>
<script>
// import groupSuandok from "@/components/groupSuandok";
import cardBlog from "@/components/cardBlog";
import cardBlogLong from "@/components/cardBlogLong";
import { BLOG } from "@/service/axios.js";
export default {
  components: {
    // groupSuandok,
    cardBlog,
    cardBlogLong,
  },
  props: {
    routeParams: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      sort: 1,
      page: 1,
      total: 0,
      pageSize: 10,
      loadData: true,
      thisData: [],
      dataCategory: [],
      all: [],
      BlogFirst: [],
      Blog: [],
      getDataSelect: "all",
      loading: true,
    };
  },
  async mounted() {
    this.setParams();
  },
  methods: {
    setParams() {
      if (this.routeParams != null) {
        if (typeof this.routeParams.type != "undefined") {
          console.log("this.rou");
          this.selectCategory(this.routeParams.type);
        } else {
          this.selectCategory("all");
        }
      } else {
        this.selectCategory("all");
        this.getBlog();
      }
    },
    sortArr() {
      var arrayFirst = "";
      var arrayAll = "";
      if (this.getDataSelect == "all") {
        this.thisData.sort((a, b) => {
          let modifier = this.sort;
          if (a.created_at < b.created_at) return -1 * modifier;
          if (a.created_at > b.created_at) return 1 * modifier;
          return 0;
        });
        arrayFirst = this.thisData.slice(0, 1);
        arrayAll = this.thisData.slice(1, this.thisData.length);
      } else {
        this.dataCategory.sort((a, b) => {
          let modifier = this.sort;
          if (a.created_at < b.created_at) return -1 * modifier;
          if (a.created_at > b.created_at) return 1 * modifier;
          return 0;
        });
        arrayFirst = [];
        arrayAll = this.dataCategory;
      }
      this.BlogFirst = arrayFirst;
      this.Blog = arrayAll;
    },
    async selectCategory(command) {
      this.loading = true;

      this.getDataSelect = command;
      if (command == "all") {
        this.pageSize = 10;
        await this.getBlog();
        setTimeout(() => {
          console.log(this.all.length);
          var arrayFirst = this.all.slice(0, 1);
          var arrayAll = this.all.slice(1, this.all.length);
          this.BlogFirst = arrayFirst;
          this.Blog = arrayAll;
          setTimeout(() => {this.loading = false;},1000)

        }, 600);
      } else {
        BLOG.get(`posts/?key=78503f5d740a46935840ac886a&filter=tag:${command}`)
          .then((res) => {
            if (res.status == 200) {
              this.dataCategory = res.data.posts;
              for (var y = 0; y < this.dataCategory.length; y++) {
                this.dataCategory[y].category = [];
                var getCategory = { name: command };
                this.dataCategory[y].category.push(getCategory);
              }
              this.BlogFirst = [];
              this.total = this.dataCategory.length;
              this.page = 1;
              this.pageSize = 9;
              this.subarr = this.dataCategory.slice(0, this.pageSize);
              this.Blog = this.subarr;

              setTimeout(() => {this.loading = false;},1000)
            } else {
              this.alertFailError();
              this.loading = false;
            }
          })
          .catch((e) => {
            this.alertCatchError(e);
            console.log("get blog error");
            this.loading = false;
          });
      }
    },
    getBlog() {
      BLOG.get(
        `posts?key=78503f5d740a46935840ac886a&page=${this.page}&limit=${this.pageSize}`
      )
        .then(async (res) => {
          if (res.status == 200) {
            this.thisData = res.data.posts;
            for (var a = 0; a < this.thisData.length; a++) {
              this.thisData[a].category = [];
            }
            console.log("asdasdasdad", this.thisData);
            await BLOG.get(`tags?key=78503f5d740a46935840ac886a`)
              .then(async (res) => {
                if (res.status == 200) {
                  var tags = res.data.tags;
                  for (var i = 0; i < tags.length; i++) {
                    var changeString = tags[i].slug;
                    await BLOG.get(
                      `posts/?key=78503f5d740a46935840ac886a&filter=tag:${changeString}`
                    )
                      .then((res) => {
                        if (res.status == 200) {
                          for (var y = 0; y < this.thisData.length; y++) {
                            var checkObj = res.data.posts.find(
                              (e) => e.id === this.thisData[y].id
                            );
                            if (checkObj != undefined) {
                              var getCategory = { name: changeString };
                              this.thisData[y].category.push(getCategory);
                            }
                          }
                        } else {
                          this.alertFailError();
                        }
                      })
                      .catch((e) => {
                        this.alertCatchError(e);
                        console.log("get blog error");
                      });
                  }
                } else {
                  this.alertFailError();
                }
              })
              .catch((e) => {
                this.alertCatchError(e);
                console.log("get blog error");
              });

            var arrayFirst = this.thisData.slice(0, 1);
            var arrayAll = this.thisData.slice(1, res.data.posts.length);
            this.all = this.thisData;
            this.BlogFirst = arrayFirst;
            this.Blog = arrayAll;
            this.total = res.data.meta.pagination.total;
            this.countAll = this.all.length;
            this.loading = false;
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("get blog error");
        });
    },
    openModalLogin() {
      this.$store.commit("SET_MODAL", true);
      this.$store.commit("TAB_LOGIN", true);
    },
    changePage(page) {
      this.loading=true;
      this.page = page;
      this.getBlog();
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
      setTimeout(() => {this.loading = false;},1000)
    },
    changePageCustom(page) {
      this.loading=true;
      var last = page * this.pageSize;
      var start = (page - 1) * this.pageSize;
      this.subarr = this.dataCategory.slice(start, last);
      this.Blog = this.subarr;
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
      setTimeout(() => {this.loading = false;},1000)
    },
  },
};
</script>