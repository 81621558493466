<template>
  <el-card class="card-blog">
    <el-row :gutter="30" class="blog-long">
      <el-col :span="24" :sm="24" :md="16" class="box-l">
        <a class="linknews" :href="`/blog/detail/${dataBlog.id}`">
          <div class="box-zoom-img">
            <div class="overlay">
              <span class="name-in-overlay">{{ dataBlog.title }}</span>
              <img :src="getGhostImage(dataBlog.feature_image,600)" />
            </div>
          </div>
        </a>
      </el-col>

      <el-col :span="24" :sm="24" :md="8" class="box-r" style="">
        <div class="box-detail-blog">
          <a class="linknews" :href="`/blog/detail/${dataBlog.id}`">
            <p class="subtitle-blog">{{ dataBlog.excerpt }}</p>
          </a>
          <div class="category-blog">
            <p v-for="(category, index) in dataBlog.category" :key="index">
              <span
                class="type1"
                v-if="category.name == 'service'"
                @click="clickCategory('service')"
                >Service</span
              >
              <span
                class="type2"
                v-if="category.name == 'innovation-2'"
                @click="clickCategory('innovation-2')"
                >Innovation</span
              >
              <span
                class="type3"
                v-if="category.name == 'business'"
                @click="clickCategory('business')"
                >Business</span
              >
              <span
                class="type4"
                v-if="category.name == 'startup'"
                @click="clickCategory('startup')"
                >Startup</span
              >
              <span
                class="type5"
                v-if="category.name == 'article'"
                @click="clickCategory('article')"
                >Article</span
              >
              <span
                class="type6"
                v-if="category.name == 'event'"
                @click="clickCategory('event')"
                >Event</span
              >
              <span
                class="type7"
                v-if="category.name == 'news'"
                @click="clickCategory('news')"
                >News</span
              >
              <span
                class="type8"
                v-if="category.name == 'home'"
                @click="clickCategory('home')"
                >Home</span
              >
            </p>
          </div>
          <p class="time">
            SMID <span>{{ dataBlog.updated_at | timeTh }}</span>
          </p>
        </div>
      </el-col>
    </el-row>
  </el-card>
</template>
<script>
export default {
  props: {
    dataBlog: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    clickCategory(type) {
      this.$emit("dataCategory", type);
    },
  },
};
</script>
<style lang="scss" scope>
.type1 {
  padding: 0 10px;
  border-radius: 10px;
  color: #ffffff;
  background-color: #3399e0;
}
.type2 {
  padding: 0 10px;
  border-radius: 10px;
  color: #ffffff;
  background-color: #1ebc9b;
}
.type3 {
  padding: 0 10px;
  border-radius: 10px;
  color: #ffffff;
  background-color: #9b59b2;
}
.type4 {
  padding: 0 10px;
  border-radius: 10px;
  color: #ffffff;
  background-color: #e5ac27;
}
.type5 {
  padding: 0 10px;
  border-radius: 10px;
  color: #ffffff;
  background-color: #e67e22;
}
.type6 {
  padding: 0 10px;
  border-radius: 10px;
  color: #ffffff;
  background-color: #f06363;
}
.type7 {
  padding: 0 10px;
  border-radius: 10px;
  color: #ffffff;
  background-color: #465c73;
}
.category-blog {
  width: 100% !important;
  max-width: 344px !important;
}
</style>